import React from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import useAuth from "../hooks/useAuth";
import { isProd } from "../lib/env";

export function initSentry() {
  // Comment out the isProd check to test sending errors to Sentry in dev.
  if (isProd) {
    Sentry.init({
      dsn: "https://cc336ead2feda1142cadce7f21d0b248@o198815.ingest.sentry.io/4505864241872896",

      // Sample 10% of non-error sessions and 100% of error sessions
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
          // This is the default but I'm setting just to explicitly call out here
          // that we aren't recording any user inputs
          maskAllInputs: true,
        }),
      ],
      environment: process.env.NODE_ENV,
      release: process.env.GIT_SHA,
      beforeSend: (event, hint) => {
        // Filter out alerts handled by the 404 page.
        const originalException: any = hint?.originalException;

        if (!originalException || typeof originalException === "string") {
          return event;
        }

        if (originalException.message === "Not found") {
          return null;
        }

        // Filter out third-party library errors. `exceptions` are lists of
        // keywords where all of the keywords must be present in the alert stack
        // in order to be filtered out.
        const exceptions: { message: string; name: string; stack: string[] }[] =
          [];

        if (
          exceptions.some(
            (exception) =>
              originalException.message?.includes(exception.message) &&
              originalException.name?.includes(exception.name) &&
              exception.stack.every((stackKeyword) =>
                originalException.stack?.includes(stackKeyword)
              )
          )
        ) {
          return null;
        }

        return event;
      },
    });
  }
}

/**
 * Ensures that the current user / email is sent in the Sentry scope.
 */
export const SentryUserListener: React.FunctionComponent = () => {
  const auth = useAuth();

  const profileOrganization =
    auth.status === "logged in" && auth.profileOrganization;

  React.useEffect(() => {
    if (!profileOrganization) {
      return;
    }

    const email = auth.firebaseEmail;
    const organizationKey = profileOrganization.id;

    Sentry.configureScope((scope) => {
      scope.setUser({ email });
      scope.setExtra("organization", organizationKey);
    });

    return () => {
      Sentry.configureScope(() => {});
    };
  }, [profileOrganization, auth]);

  return null;
};
